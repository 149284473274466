import '../styles/homeandlifepage.css';

import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import bitmap_2 from '../images/bitmap_2.png';
import Illustration from '../images/illustration-bubble-tip.png';
import bitmap_1 from '../images/images/bitmap.png';
import girlwithcat from '../images/images/group-3.png';
import PH_Image from '../images/images/group-8.png';
import righttick from '../images/images/icon-tick-green.svg';
import stillwaterLogo from '../images/stillwater-logo.png';

const StillWaterPage = ( { data, parent } ) => {

  return (
    <Layout header={'menu'}>
      <SEO
        title="Home insurance by Stillwater, stillwater, Best Life Insurance Policy, Life insurance quotes online, Online insurance"
        canonicalUrl='https://www.getmybubble.com/stillwater/'
        description="Home insurance by Stillwater, stillwater, Home insurance quotes online, Online  home insurance policy"
        keywords="Home insurance by Stillwater, stillwater, Home insurance quotes online, Online  home insurance policy"
      />

      {/* <Menu /> */}

      <div className="flexColumn carrierCommonPage">
        <div className="container-fluid carrier-plan-container_1">
          {/* carrier-plan-container */}

          <div className="container">
            <div className="row">
              <div className="col-lg-6 ">
                <div className="padding-left">
                  <img src={stillwaterLogo} alt="logo"/>

                  <h1
                    className="carrier-plan-heading"
                  >
                    Start protecting the place that you and your loved ones call home.
                  </h1>

                  <p className="carrier-plan-section1-para">
                    Simply put, a homeowners policy should make you financially "whole" again if your home is damaged or destroyed by events like fire or storm, or is burglarized.
                  </p>

                  <div className="flexRow align-btns ml-1">
                    <Link to="/">
                      <button type="button" className="orangeBtn mr-3">
                      Get a quote now
                      </button>
                    </Link>

                    <a href="#start-exploring" style={{ color: '#ff7007', textDecoration: 'none' }}>
                      <button type="button" className="explore">
                        Explore
                      </button>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 sbli-image" style={{ textAlign: 'center' }}>
                <img src={girlwithcat} className="carrier-right-img-mv mt-4 mt-sm-4 mt-md-4 mt-lg-0" alt="" />
              </div>
            </div>
          </div>

        </div>

        {/*section 2*/}

        <div id="start-exploring" className="container-fluid" style={{ marginTop: '135px' }}>
          <div className="row">
            <div className="col-lg-3"></div>

            <div className="col-lg-6 costom-padding19">
              <h3 className="stillwater-title1">
                Why to choose Stillwater Homeowners Insurance?
              </h3>

              <p className="stillwater-para1">
                Simply put, a homeowners policy should make you financially "whole" again if your home is damaged or destroyed by events like fire or storm, or is burglarized. With Stillwater, you can build a policy that'll help you stay financially whole if the unthinkable happens.
              </p>

            </div>

            <div className="col-lg-3"></div>
          </div>
        </div>

        {/* Product Highlights Section */}

        <div className="container-fluid">
          <div className="row" style={{ marginTop: '30px' }}>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-2"></div>

                <div className="col-lg-9 product-container-alignment costom-padding19">
                  <div className="stillwater-product-container">

                    <div className="align-horizontal-center">
                      <img src={PH_Image} className="ph-desktop-view" alt="phdesk"/>

                      <h3
                        className="ph-title ph-desktop-view">
                        Product Highlights
                      </h3>

                      <h3
                        className="ph-title ph-mobile-view">
                        Product Highlights
                      </h3>

                      <img src={PH_Image} className="ph-mobile-view" alt="phmob"/>
                    </div>

                    <div
                      style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} className="img-responsive" alt="tick" style={{ verticalAlign: 'middle' }}></img>

                      <div className="ph-item-style pl-3">
                        Pays claims based on the cost to rebuild your home. That can be more than your home's market value.
                      </div>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} alt="tick"/>

                      <div className="ph-item-style pl-3">
                        Pays the replacement cost of your home's contents, not a depreciated value based on its age or condition.
                      </div>

                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} alt="tick"/>

                      <div className="ph-item-style pl-3">
                        Offers deductible options, and discounts that save you money.
                      </div></div>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} alt="tick"/>

                      <div className="ph-item-style pl-3">
                        Pays for damage to, or theft of, most personal items including appliances, furniture, electronics, and clothing.
                      </div></div>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} alt="tick"/>

                      <div className="ph-item-style pl-3">
                        Pays the bills or expenses you have as a result of losing the use of your residence.
                      </div></div>

                  </div>

                </div>

                <div className="col-lg-1"></div>
              </div>
            </div>

            <div className="col-lg-6 carrier-middle-container carrier-middle-container-mv">
              <h4 className="stillwater-col2-title">Choose a deductible that fits your budget</h4>

              <p className="stillwater-col2-para">
                The deductible - which you choose when you buy a policy - is the amount you have to pay before the insurance company starts to pay for a covered loss. Generally speaking, the higher the deductible you choose, the lower your premium.
              </p>

              <h4 className="stillwater-col2-title">Save money with discounts</h4>

              <p className="stillwater-col2-para">
                The sense of peace that comes with a Stillwater Homeowners policy is available at an affordable rate.. and with discounts that will help you save.
              </p>

              <ul className="stillwater-list stillwater-list-item stillwater-list-mv">
                <li>
                  Accredited Builder
                </li>

                <li>Age of Insured
                </li>

                <li>Automatic Sprinkler
                </li>

                <li>Gated Community
                </li>

                <li>Home Alarm Protection
                </li>

                <li>Marital Status
                </li>

                <li>Multi-Policy
                </li>

                <li>Newly Acquired Home
                </li>

                <li>Roof-Type
                </li>

                <li>Utility Upgrade
                </li>
              </ul>

              <h4 className="stillwater-col2-title">Will insure homeowners with subpar credit and prior policy lapses</h4>

              <p className="stillwater-col2-para">
                One of this company's most notable traits is that they write policies for most home types and circumstances — even homes that didn’t have insurance prior to applying with Stillwater. So if you’ve been given the thumbs down by carrier after carrier because your home wasn’t insured, Stillwater may be your best bet.
              </p>

              <h4 className="stillwater-col2-title">Multiple discount opportunities</h4>

              <p className="stillwater-col2-para">
                Stillwater offers policyholders discounts on protective devices, or if your home is built by an accredited builder, together with bundling discounts. There is no shortage of ways to keep your premiums low with Stillwater.
              </p>

              <h4 className="stillwater-col2-title">Easily customizeable policies</h4>

              <p className="stillwater-col2-para">
                Whether you're interested in the bare-minimum coverage amounts or a full-on replacement cost policy with maximum coverage limits for each component and multiple policy endorsements, Stillwater has you covered.
              </p>


            </div>
          </div>
        </div>

        <div className="container stillwater-card-container">
          <div className="row">
            <div className="col-lg-3">
            </div>

            <div className="col-lg-6">
              <h3 className="stillwater-title1">When it comes to protecting your assets, we make the grade.</h3>

              <div className="card-deck align-stillwater-cards">

                <div className="row">
                  <div className="col-md-6 col-lg-6 section30 costom-padding19">
                    <div className="card stillwater-cards">
                      <div className="card-header align-stillwater-card-header">
                        <img src={bitmap_1} alt="bitmap"/>
                      </div>

                      <div className="card-body bg-card-body">
                        <p className="stillwater-card-text">Has the financial strength and capability to guarantee our customers' polices and keep them secure.</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6 section30 costom-padding19">
                    <div className="card stillwater-cards">
                      <div className="card-header align-stillwater-card-header">
                        <img src={bitmap_2} alt="bitmap"/>
                      </div>

                      <div className="card-body bg-card-body">
                        <p className="stillwater-card-text">Proven track record of predicting financial stability in the insurance industry and substantiates</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3">
          </div>
        </div>

        <div className="plansForYou">
          <div className="orangeBackGround">
            <div>
              Find out which plan is right for you
            </div>

            <div className="customizedPlan">
              Policies are customized according to your needs.
              We have simplified the process to help you find the right policy that fits your budget and loved ones.
            </div>

            <Link to="/" type="button" className="btnWhite">
              Get your quote now
            </Link>
          </div>
        </div>
      </div>
    </Layout> );
};

export default StillWaterPage;
